
























































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ConfirmModal, ButtonGroup } from '@/app/components';
import CronSchedule from '@/modules/data-checkin/views/harvester/Schedule.vue';
import { CreateScheduleDTO, UpdateScheduleDTO } from '../../dto';

export default defineComponent({
    name: 'ScheduleEditor',
    components: {
        ConfirmModal,
        ValidationProvider,
        ButtonGroup,
        CronSchedule,
        ValidationObserver,
    },
    props: {
        workflowId: {
            type: [String, Number],
            required: true,
        },
        schedule: {
            type: Object,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        differencesExist: {
            type: Boolean,
            default: false,
        },
        frameworkVersion: {
            type: String,
            default: null,
        },
        retrievalType: {
            type: String,
            default: 'periodic',
        },
    },
    setup(props, { emit }) {
        const frequencies = ['hourly', 'daily', 'weekly', 'monthly'];
        const showChangeScheduleModal = ref(false);
        const newFrequency = ref(null);
        const scheduleValidationRef = ref<any>(null);
        const editMode = ref<boolean>(false);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const retrieveOnce = computed(() => props.retrievalType && props.retrievalType === 'once');

        const configuration: any = ref(null);
        const compareScheduleToBeCreated: any = ref(null);

        const updateSchedule = (schedule: any) => {
            if (schedule) {
                configuration.value = R.clone(schedule);
                editMode.value = true;
            } else {
                configuration.value = { frequency: 'daily', startDate: null, endDate: null, schedule: '* * * * *' };
                compareScheduleToBeCreated.value = R.clone(configuration.value);
                editMode.value = false;
            }
            if (scheduleValidationRef.value !== null) {
                scheduleValidationRef.value.reset();
            }
        };

        const updateConfiguration = () => {
            if (props.error) {
                emit('reset-error');
            }

            if (editMode.value) {
                emit('changed-schedule', configuration.value);
            } else {
                if (JSON.stringify(configuration.value) !== JSON.stringify(compareScheduleToBeCreated.value)) {
                    emit('creation-differences', true);
                    emit('set-toggle-click-event');
                } else {
                    emit('creation-differences', false);
                }
            }
        };

        watch(
            () => props.schedule,
            (schedule: any) => updateSchedule(schedule),
        );
        updateSchedule(props.schedule);

        watch(
            () => configuration.value,
            () => updateConfiguration,
            { deep: true },
        );
        updateConfiguration();

        const addSchedule = () => {
            configuration.value.schedule = '* * * * *';
        };

        const changeFrequency = (frequency: any) => {
            configuration.value.schedule = '';
            configuration.value.frequency = frequency; // eslint-disable-line no-param-reassign
            showChangeScheduleModal.value = false;

            addSchedule();
        };

        const checkChangeSchedule = (frequency: any) => {
            if (configuration.value.schedule !== '') {
                newFrequency.value = frequency;
                showChangeScheduleModal.value = true;
            } else if (frequency !== configuration.value.frequency) {
                changeFrequency(frequency);
            }
        };

        const saveSchedule = async () => {
            if (scheduleValidationRef.value) {
                const valid = await scheduleValidationRef.value.validate();

                if (valid) {
                    const cronSchedule = configuration.value.schedule.split(' ');
                    const commonPayload = {
                        startDate: configuration.value.startDate,
                        endDate: configuration.value.endDate,
                        frequency: configuration.value.frequency,
                        minute: Number(cronSchedule[0]) >= 0 ? Number(cronSchedule[0]) : null,
                        hour: Number(cronSchedule[1]) >= 0 ? Number(cronSchedule[1]) : null,
                        dayOfMonth: Number(cronSchedule[2]) >= 0 ? Number(cronSchedule[2]) : null,
                        month: Number(cronSchedule[3]) >= 0 ? Number(cronSchedule[3]) : null,
                        dayOfWeek: Number(cronSchedule[4]) >= 0 ? Number(cronSchedule[4]) : null,
                        configuration: props.frameworkVersion ? { frameworkVersion: props.frameworkVersion } : {},
                    };
                    if (editMode.value) {
                        const editPayload: UpdateScheduleDTO = { ...commonPayload };
                        emit('update', editPayload);
                        emit('saved');
                    } else {
                        const createPayload: CreateScheduleDTO = {
                            ...commonPayload,
                            entityId: props.workflowId,
                            entityType: 'Workflow',
                        };
                        emit('save', createPayload);
                        // emit('set-toggle-click-event');
                        emit('creation-differences', false);
                        emit('saved');
                    }
                }
            }
        };

        const cancelScheduleEditing = () => {
            // emit('creation-differences', false);
            emit('cancel');
        };

        watch(
            () => props.retrievalType,
            (retrieval: string) => {
                if (retrieval) {
                    configuration.value = { frequency: 'daily', startDate: null, endDate: null, schedule: '* * * * *' };
                }
            },
        );

        const setEndDate = () => {
            configuration.value.endDate = new Date(R.clone(configuration.value.startDate).setUTCHours(23, 59, 59));
        };

        return {
            frequencies,
            configuration,
            showChangeScheduleModal,
            addSchedule,
            newFrequency,
            checkChangeSchedule,
            dateYesterday,
            changeFrequency,
            emit,
            saveSchedule,
            editMode,
            scheduleValidationRef,
            cancelScheduleEditing,
            retrieveOnce,
            setEndDate,
        };
    },
});
